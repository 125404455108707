<template>
  <DSingleView color="green" title="Wallet">
    <div class="py-8 xl:py-10 px-4 sm:px-6 max-w-5xl mx-auto">
      <ApolloQuery
        :query="listWalletItems"
        :variables="{ id: walletId }"
        v-slot="{ result: { data }, isLoading }">
        <div class="grid grid-cols-2 gap-y-12 gap-x-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          <template v-if="isLoading">
            <div v-for="n in 16" :key="n" class="group animate-pulse">
              <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden"></div>
              <div class="mt-4 bg-gray-200 h-4 w-2/3 rounded"></div>
              <div class="mt-1 bg-gray-200 h-6 w-full rounded"></div>
            </div>
          </template>
          <template v-else-if="data.walletItems.length">
            <div v-for="item in data.walletItems" :key="item.id" class="group">
              <div class="relative">
                <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden">
                  <img :src="item.thumbnailUrl" class="w-full h-full object-center object-cover group-hover:opacity-75" />
                </div>
              </div>
              <div class="flex justify-between mt-4">
                <div>
                  <h3 class="text-sm text-gray-700">
                    {{ item.dropName }}
                  </h3>
                  <p class="text-lg font-medium text-gray-900 truncate">
                    {{ item.name }}
                  </p>
                </div>
                <RarityLabel :token="item" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-span-2 sm:col-span-3 lg:col-span-4">
              <main class="sm:mt-8 sm:flex justify-center">
                <p class="text-4xl font-extrabold text-green-600 sm:text-5xl">:(</p>
                <div class="mt-4 sm:mt-0 sm:ml-6">
                  <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                    <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      No tokens found
                    </h1>
                    <p class="mt-1 text-base text-gray-500">
                      Select a different wallet in the sidebar or mint your first Diamond Hands Hotel NFT.
                    </p>
                  </div>
                  <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                    <AppLink :to="{ name: 'ExploreDrops' }" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                      Explore drops
                    </AppLink>
                    <AppLink :to="{ name:'ExploreDrop', params: { slug: 'spacesneks' } }" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                      Mint a Space Snek
                    </AppLink>
                  </div>
                </div>
              </main>
            </div>
          </template>
        </div>
      </ApolloQuery>
    </div>
  </DSingleView>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { listWalletItems } from '@/gql/wallet'
import { SnekIcon } from '@ui'
import DSingleView from '@/components/ui/layout/DSingleView.vue'
import RarityLabel from '@/components/roof/tokens/rarity/RarityWalletLabel.vue'
import AppLink from '@/components/roof/AppLink.vue'

const store = useStore()
const walletId = computed(() => {
  const wallet = store.getters['auth/activeWallet']
  return wallet ? wallet.id : undefined
})
</script>